.custom-legend {
  width: 1rem;
  height: 1rem;
}

.recharts-default-legend {
  display: flex !important;
  align-items: center;

  .recharts-legend-item {
    display: flex !important;
    align-items: center;
    margin: 0 6px !important;
  }
}
