.t-menu * {
    text-transform: none !important;
  }
  
  .t-menu .arrow-box {
    position: relative;
    top: 10px;
    background: #88b7d5;
  }
  .t-menu .arrow-box:after,
  .t-menu .arrow-box:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .t-menu .arrow-box:after {
    border-bottom-color: #ffffff;
    border-width: 8px;
    margin-left: -8px;
  }
  .t-menu .arrow-box:before {
    border-bottom-color: #c7e6ff;
    border-width: 11px;
    margin-left: -11px;
  }
  