html,
body,
#root,
#root > div {
  height: 100%;
  line-height: 1.5;
  background: url('../../images/background.jpg');
  font-size: 87.5%;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

body {
  box-shadow: none !important;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.01em !important;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 0.5em !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black: #000;

$blue: #127abf;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #e74a3b;
$orange: #ee7f01;
$yellow: #f6c23e;
$green: #449414;
$teal: #36b9cc;
$cyan: #9954bb;
$lightblue: lighten($blue, 20%);

$primary: $blue;
$secondary: $orange;
$success: $green;
$info: $teal;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$brand-primary: #127abf;
$brand-secondary: #449414;

@import '~bootswatch/dist/cosmo/_variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootswatch/dist/cosmo/_bootswatch.scss';

hr {
  opacity: 1;
}

legend {
  float: unset;
}
