.form-container input[type='text'],
.form-container input[type='number'] {
  padding: 16px 14px;
}

.form-container {
  display: flex !important;
  height: 82vh;
}

.form-container .container {
  margin-right: 0 !important;
  width: 100%;
}

.form-container #crf-container {
  margin-right: 0 !important;
  width: 100% !important;
}

.form-container .tabs {
  width: 25%;
  min-width: 25%;
  padding: 10px;
  border-right: 2px solid #00509218;
}

.form-container a[role='tab'] {
  min-width: unset !important;
  max-width: unset !important;
  margin-bottom: 10px;
  border: 2px solid #418cfe;
  color: #3c546b !important;
  text-transform: none;
  font-size: 1rem !important;
  font-weight: 600 !important;
  border-radius: 7px;
  padding: 12px 8px !important;
}

.form-container a[role='tab'].Mui-selected {
  background-color: #008cff1a;
  color: #008cff !important;
  border: 2px solid #418cfe;
}

.form-container .tab-indicator {
  display: none !important;
}

.form-container .tab-panel {
  height: 100%;
}

.form-container .form-fields {
  height: 75vh;
  max-height: 75vh;
  overflow-x: scroll;
}

.form-container .form-fields.no-submit {
  height: 80vh;
  max-height: 80vh;
}

.form-container .form-html-content p,
.form-container .form-html-content ol,
.form-container .form-html-content ul,
.form-container .form-html-content div,
.form-container .form-html-content span {
  color: #2c3e50 !important;
}

@media (min-width: 600px) {
  .form-container a[role='tab'] {
    text-transform: none;
    font-size: 17px;
    padding: 12px 8px;
  }
}
