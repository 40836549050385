.flex {
  display: flex;
}

.br-50 {
  border-radius: 50%;
}

.custom-box {
  background: $white 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 10px #0000001a !important;
  border-radius: 16px !important;
}

.custom-box-small {
  background: $white 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 10px #0000001a !important;
  border-radius: 8px !important;
}

.custom-modal {
  // background-color: #f2f9ff !important;
  border-radius: 8px !important;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) !important;
}

.custom-modal-document {
  // background-color: #f2f9ff !important;
  border-radius: 10px !important;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) !important;
}

.sheet-of-paper {
  -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.dropzone {
  height: 6rem;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px dashed #7c7f84;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #7c7f84;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.message-card {
  width: 100%;
  text-align: center;
}

.message-card-negative {
  background-color: #f443361c !important;
}

.mw-3 {
  min-width: 3rem;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
}

.list-item-selectable:hover {
  cursor: pointer;
}

.list-item-selected {
  box-shadow: 0px 0px 0px 4px #0075ff5e !important;
  background-color: #e8f4ff;
}

.scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.scrollbar::-webkit-scrollbar-button {
  display: none;
}

.blurred-background {
  backdrop-filter: blur(10px);
  background-color: #0000004d !important;
}

.hover-pointer:hover {
  cursor: pointer;
}
