@mixin full-screen-parent {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}

@mixin full-screen-child {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

@mixin half-width {
  min-height: 100%;
  overflow: hidden;
  width: 50%;
}

@mixin display-row {
  display: flex;
  flex-direction: row;
}

@mixin display-column {
  display: flex;
  flex-direction: column;
}

@mixin align-justify-center {
  align-items: center;
  justify-content: center;
}
