.wearables-widgets {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 104px;
    background: #f8f8ff;
    border-radius: 8px;
    margin-bottom: 10px;
}


.wearables-div {
    display: flex;
    max-width: 104px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}