.logo {
  height: 1.9em;
}

.text-blue {
  color: $brand-primary;
}

.text-green {
  color: $brand-secondary;
}

.select-menu {
  max-height: 290px !important;
  overflow: auto !important;
  border: 2px solid #9bc3ff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 16px !important;
  border-radius: 4px !important;
}

.select-menu-item {
  padding: 10px 15px !important;
  border-bottom: 1px solid #dde5f0 !important;
}

.required-field:after {
  content: ' *';
  color: red;
}

@import '~formiojs/dist/formio.full.css';
