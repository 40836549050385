.index-container {
  @include display-column();
  overflow: overlay;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

.app-container {
  @include display-column();
  flex: 1;
  background: url("../../images/background.jpg");
  background-size: 100%;
}

*::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
