%font-base {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
}

.fs-xxlarge {
  @extend %font-base;

  font-size: 3.125rem !important;
}

.fs-large {
  @extend %font-base;

  font-size: 2.5rem !important;
}

.fs-xxxxxl {
  @extend %font-base;

  font-size: 2rem !important;
}

.fs-xxxxl {
  @extend %font-base;

  font-size: 1.75rem !important;
}

.fs-xxxl {
  @extend %font-base;

  font-size: 1.5rem !important;
}

.fs-xxl {
  @extend %font-base;
  font-size: 1rem !important;
}

.fs-xxl-temp {
  @extend %font-base;
  font-size: 1.25rem !important;
}

.fs-xl {
  @extend %font-base;

  font-size: 1.125rem !important;
}

.fs-l {
  @extend %font-base;

  font-size: 1rem !important;
}

.fs-m {
  @extend %font-base;

  font-size: 0.875rem !important;
}

.fs-s {
  @extend %font-base;

  font-size: 0.75rem !important;
}

.fs-xs {
  @extend %font-base;

  font-size: 0.625rem !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.c-subheading-text {
  color: #2c3e50 !important;
}

.c-content-text {
  color: #2c3e50 !important;
}

.c-disabled-text {
  color: #8b9095 !important;
}

.tt-none {
  text-transform: none !important;
}

.tt-uppercase {
  text-transform: uppercase !important;
}

.tt-italics {
  font-style: italic !important;
}

.c-red {
  color: #db4e54 !important;
}

.c-green {
  color: #47bfa1 !important;
}

.c-blue {
  color: #008cff !important;
}

.wh-nowrap {
  white-space: nowrap !important;
}

.elevated-1 {
  box-shadow: 0px 3px 5px #00000014 !important;
}
