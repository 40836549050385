.ag-react-container {
  height: 100%;
  width: 100%;
  font-family: 'Poppins';
  font-weight: 500;
  color: #3a3a3a;
  font-size: 1rem;
  white-space: 'normal !important';
  word-break: break-word !important;
}

.ag-root-wrapper {
  border: none;
  border-color: #babfc7;
  border-radius: 0.25rem;
}

.ag-header-cell {
  color: #008cff;
  background: rgba(0, 140, 255, 0.2) 0% 0% no-repeat padding-box;
  &:first-child {
    padding-left: 1.25rem;
  }
  font-weight: 600 !important;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
  text-transform: uppercase;
  // for waitlisting tag
  padding-left: 1.6875rem;
}

.ag-icon {
  font-family: 'agGridMaterial';
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ag-menu {
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 0.125rem;
  box-shadow: 0 0.1875rem 0.0625rem -0.125rem rgba(0, 0, 0, 0.2),
    0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.14),
    0 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.12);
  padding: 0.5rem;
  padding: 0;
}
.ag-chart-menu-icon {
  opacity: 0.5;
  line-height: 1.5rem;
  font-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.125rem 0;
  cursor: pointer;
  border-radius: 0.125rem;
  color: rgba(0, 0, 0, 0.54);
  color: var(--ag-secondary-foreground-color, rgba(0, 0, 0, 0.54));
}

.ag-header-icon.ag-header-cell-menu-button {
  opacity: 1 !important;
  margin-right: 0.3125rem;
}
.ag-cell-label-container {
  flex-direction: row !important;
}
.ag-header-icon.ag-header-label-icon {
  opacity: 1 !important;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.ag-menu {
  background: #fff;
  background: var(--ag-background-color, #fff);
  border-radius: 0.125rem;
  box-shadow: 0 0.1875rem 0.0625rem -0.125rem rgba(0, 0, 0, 0.2),
    0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.14),
    0 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.12);
  padding: 0.5rem;
  padding: 0;
}
.ag-ltr .ag-label-align-right .ag-label {
  margin-left: 0.5rem;
}
.ag-rtl .ag-label-align-right .ag-label {
  margin-right: 0.5rem;
}
.ag-filter-condition-operator {
  height: 1.0625rem;
}

.ag-simple-filter-body-wrapper {
  padding: 1rem 0.75rem;
  padding-bottom: 0.125rem;
}
.ag-simple-filter-body-wrapper > * {
  margin-bottom: 0.875rem;
}
.ag-popup-child:not(.ag-tooltip-custom) {
  box-shadow: 0.3125rem 0.3125rem 0.625rem rgba(0, 0, 0, 0.3);
}
.ag-dragging-range-handle .ag-dialog,
.ag-dragging-fill-handle .ag-dialog {
  opacity: 0.7;
  pointer-events: none;
}
.ag-select .ag-picker-field-wrapper {
  background-color: #fff;
  background-color: var(--ag-background-color, #fff);
  min-height: 2rem;
  cursor: default;
}

.ag-select .ag-picker-field-display {
  margin: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ag-select .ag-picker-field-icon {
  display: flex;
  align-items: center;
}

.ag-picker-field-wrapper {
  border: 0.0625rem solid;
  border-color: #e2e2e2;
  border-color: var(--ag-border-color, #e2e2e2);
  border-radius: 0.3125rem;
}
.ag-picker-field-wrapper:focus {
  box-shadow: 0 0 0 0.3125rem rgba(32, 33, 36, 0.122);
}
.ag-select .ag-picker-field-display {
  margin: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ag-ltr {
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.ag-filter {
  width: 15.625rem;
}

.ag-row {
  background-color: white;
  border: 0.25rem solid white;
  box-shadow: inset 0rem 0rem 0.375rem #0000001a;
  min-height: 4.5rem !important;
}

.ag-row-hover {
  background: #f2f9ff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #008cff;
  box-shadow: inset 0rem 0rem 0.375rem #10101029;
}

.customHeaderMenuButton,
.customSortDownLabel,
.customSortUpLabel,
.customSortRemoveLabel {
  opacity: 1 !important;
}

.grid-cell-4n {
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    width: 0.125rem;
    height: 2.5rem;
    background: #bfe2ff;
    right: 0;
  }

  &:nth-child(4n) {
    border-radius: 0.625rem;
    &::after {
      width: 0rem;
    }
  }
}

.grid-cell-5n {
  width: 100%;
  &:nth-child(5n) {
    border-radius: 0.625rem;
    &::after {
      width: 0rem;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 0.125rem;
    height: 2.5rem;
    background: #bfe2ff;
    right: 0;
  }
}

.grid-cell-6n {
  width: 100%;
  &:nth-child(6n) {
    border-radius: 0.625rem;
    &::after {
      width: 0rem;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 0.125rem;
    height: 2.5rem;
    background: #bfe2ff;
    right: 0;
  }
}

.grid-cell-7n {
  width: 100%;
  &:nth-child(7n) {
    border-radius: 0.625rem;
    &::after {
      width: 0rem;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 0.125rem;
    height: 2.5rem;
    background: #bfe2ff;
    right: 0;
  }
}

.class-green {
  color: #39da8a;
  border-right: 0.9375rem solid #39da8a;
  border-radius: 0.625rem;
}
.class-blue {
  color: #0191d6;
  border-right: 0.9375rem solid #0191d6;
  border-radius: 0.625rem;
}
.class-grey {
  color: #6b6b6b;
  border-right: 0.9375rem solid #6b6b6b;
  border-radius: 0.625rem;
}

.ag-header-icon.ag-header-cell-menu-button {
  opacity: 1 !important;
}

.ag-header-viewport {
  border-radius: 0.5rem;
}

.ag-header {
  min-height: 4rem !important;
  border-bottom: 0.25rem solid transparent;
}

.ag-header-row-column {
  height: 4rem !important;
}

.custom-tooltip {
  position: absolute;
  width: 9.375rem;
  height: 4.375rem;
  border: 0.0625rem solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.ag-root-wrapper-body .ag-checkbox-input-wrapper {
  background: none;
  box-sizing: content-box;
  margin-right: 4rem;
}

.ag-root-wrapper-body .ag-checkbox-input-wrapper::after {
  content: url('../../images/GreyCheckMark.svg');
}

.ag-root-wrapper-body .ag-checkbox-input-wrapper.ag-checked::after {
  content: url('../../images/BlueFilledCheckMark.svg');
}

.ag-root-wrapper-body .ag-checkbox-input-wrapper.ag-indeterminate::after {
  content: '\f068';
  color: gray;
}

.ag-row-selected {
  background: #f2f9ff 0% 0% no-repeat padding-box;
}

.align-column-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.testClass {
  background-color: black;
}

@media only screen and (max-width: 1368px) {
  .ag-react-container {
    font-size: 0.875rem;
  }
}
